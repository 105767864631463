export default function ButtonMuted(props) {
  return (
    <button
      className={`cursor-not-allowed rounded-full h-10 md:h-12 px-3 text-center duration-100 ease-out bg-gray-100 ${props.className}`}
    >
      <div className="">
        {props.children}
        <p className="hidden text-xs md:block">COMING SOON</p>
      </div>
    </button>
  );
}
