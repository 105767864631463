/* eslint-disable no-redeclare */
import {
  CREATE_NONTARGET,
  CREATE_SUPERSELECTOR,
  CREATE_TARGET,
  DELETE_NONTARGET,
  DELETE_SUPERSELECTOR,
  DELETE_TARGET,
  UPDATE_GENOME,
} from '../constants.js';

const initialState = {
  targets: [],
  nonTargets: [],
  superselectors: [{name: 'A'}],
  genome: {}
}

function rootReducer(state = initialState, action) {

  switch (action.type) {
    case CREATE_NONTARGET:
      /**
       * Adds a non-target object to the non-target list
       */
      return Object.assign(
        {},
        state,
        {
          nonTargets: state.nonTargets.concat(action.payload)
        }
      );
    case CREATE_SUPERSELECTOR:
      /**
       * Adds a superselector object to the non-target list
       */
      return Object.assign(
        {},
        state,
        {
          superselectors: state.superselectors.concat(action.payload)
        }
      );
    case CREATE_TARGET:
      /**
       * Adds a target object to the non-target list
       */
      return Object.assign(
        {},
        state,
        {
          targets: state.targets.concat(action.payload)
        }
      );
    case DELETE_NONTARGET:
      /**
       * Removes a non-target from the non-target list by its ID
       */
      var index = state.nonTargets.indexOf(action.payload);
      var newList = Object.assign({}, state.nonTargets);
      if (index > -1) {
        newList.splice(index, 1);
      }

      return Object.assign(
        {},
        state,
        {
          nonTargets: newList
        }
      );
    case DELETE_SUPERSELECTOR:
      /**
       * Removes a superselector from the superselector list by its ID
       */
      var index = state.superselectors.indexOf(action.payload);
      var newList = Object.assign({}, state.superselectors);
      if (index > -1) {
        newList.splice(index, 1);
      }

      return Object.assign(
        {},
        state,
        {
          superselectors: newList
        }
      );
    case DELETE_TARGET:
      /**
       * Removes a target from the target list by its ID
       */
      var index = state.targets.indexOf(action.payload);
      var newList = Object.assign({}, state.targets);
      if (index > -1) {
        newList.splice(index, 1);
      }

      return Object.assign(
        {},
        state,
        {
          targets: newList
        }
      );
    case UPDATE_GENOME:
      /**
       * Updates the genome object (removes the old and replaces with the new)
       */
      return Object.assign(
        {},
        state,
        {
          genome: action.payload
        }
      );
    default:
      return state;
  }
}

export default rootReducer;