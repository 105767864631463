import { ContainerCard } from "./core";
import { Subtitle } from "./text";
import { ButtonDark } from "./buttons";
import { useContext } from "react";
import { MainContext } from "./MainContent";

function MyInput(props) {
  const setSearchTerm = props.setSearchTerm;
  return (
    <input
      type="text"
      className={`font-normal w-16 text-center text-gray-700 bg-white border-2 border-solid border-gray-300 rounded-lg transition duration-500 ease-in-out px-1 hover:border-gray-800 focus:text-gray-700 focus:border-black focus:outline-none ${props.className}`}
      placeholder={`${props.placeholder}`} // "Search genomes..."}
      onChange={(event) => {
        setSearchTerm(event.target.value);
      }}
    ></input>
  );
}

function FilterDummyInput() {
  return <div className="hidden md:inline-block"></div>;
}

function FilterLengthInputs(props) {
  return (
    <div className="grid grid-cols-3 py-3 pr-3 rounded-lg">
      <div className="self-center">
        <span className="font-semibold">Length </span>
        <span>(nt)</span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <div>Min</div>
        <MyInput placeholder="0" setSearchTerm={props.setMin}></MyInput>
      </div>
      <div className="flex flex-row items-center gap-2 px-2">
        <div>Max</div>
        <MyInput placeholder="20" setSearchTerm={props.setMax}></MyInput>
      </div>
    </div>
  );
}

function FilterSelectivityInputs(props) {
  return (
    <div className="grid grid-cols-3 py-3 pr-3 rounded-lg">
      <div className="self-center">
        <span className="font-semibold">Selectivity </span>
        <span>(%)</span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <div>Min</div>
        <MyInput placeholder="0" setSearchTerm={props.setMin}></MyInput>
      </div>
      <div className="flex flex-row items-center gap-2 px-2">
        <div>Max</div>
        <MyInput placeholder="100" setSearchTerm={props.setMax}></MyInput>
      </div>
    </div>
  );
}

export default function Filter() {
  const {
    filterData,
    setMinLength,
    setMaxLength,
    setMinSelectivity,
    setMaxSelectivity,
  } = useContext(MainContext);
  return (
    <ContainerCard className="mx-4 my-3">
      <Subtitle>Filter Superselectors</Subtitle>
      <div className="grid mt-1 text-sm md:grid-cols-2">
        <FilterLengthInputs
          setMin={setMinLength}
          setMax={setMaxLength}
        ></FilterLengthInputs>
        <FilterDummyInput></FilterDummyInput>
        <FilterSelectivityInputs
          setMin={setMinSelectivity}
          setMax={setMaxSelectivity}
        ></FilterSelectivityInputs>
        <div className="flex justify-center px-3 mt-2 md:mt-0 md:justify-end">
          <ButtonDark className="w-36" onClick={filterData}>
            Filter
          </ButtonDark>
        </div>
      </div>
    </ContainerCard>
  );
}
