/**
 * Menu-style bar containing link to nanograb main website
 * @constructor
 */
export default function Header() {
  return (
    <div className="relative flex items-center w-full h-20 p-5 align-middle bg-black justify-left">
      <a
        href="https://nanograb.com/"
        className="flex text-3xl text-white align-middle"
      >
        nanograb
      </a>
    </div>
  );
}
