export default function ButtonMaroon(props) {
  return (
    <button
      className={`text-center self-center ease-out rounded-full transition duration-300 hover:bg-opacity-75 hover:border-gray-500 shadow-md font-semibold text-white bg-pink-900 border-0
          ${props.className}`}
      onClick={
        props.onClick
          ? props.onClick
          : () => alert("ButtonLight clicked! (no onClick set)")
      }
    >
      {props.children}
    </button>
  );
}
