export default function ButtonDark(props) {
  return (
    <button
      className={`bg-black px-3 text-center text-white duration-200 ease-out rounded-full
        hover:bg-opacity-95 transition ${
          props.height ? props.height : "h-10 md:h-12"
        }  ${props.className}`}
      onClick={
        props.onClick
          ? props.onClick
          : () => alert("ButtonDark clicked! (no onClick set)")
      }
    >
      {props.children}
    </button>
  );
}
