import { ContainerCard } from "./core";
import { Subtitle } from "./text";
import ResultsGrid from "./ResultsGrid";

import { useContext } from "react";
import { MainContext } from "./MainContent";

export default function ResultsTable(props) {
  const { data } = useContext(MainContext);

  return (
    <ContainerCard className="bg-white drop-shadow-xl">
      <div className="mx-4">
        {/* Title Row */}
        <Subtitle className="mt-2">Results</Subtitle>
        {/* Top bar */}
      </div>
      {/* TABLE GOES HERE */}
      <div className="">
        <ResultsGrid
          data={data}
          className="mt-2 md:flex md:justify-center md:mt-5"
        ></ResultsGrid>
      </div>
      <div className="pt-3 pb-2 pl-4 text-center">
        Tip: you can sort the results by clicking a header.
      </div>
    </ContainerCard>
  );
}
