import { Grid } from "gridjs-react";
import { useContext } from "react";
import { MainContext } from "./MainContent";

function ResultsGrid(props) {
  const { data } = useContext(MainContext);
  const itemLimit = 15;
  return (
    <div className={`${props.className}`}>
      <Grid
        data={data}
        sort={true}
        // resizable={true}
        columns={[
          { id: "sequence", name: "Superselector Sequence" }, // width: "25%" },
          { id: "length", name: "Length (nt)" }, //, width: "10%" },
          { id: "selectivity", name: "Selectivity (%)" }, // width: "25%" },
          // { id: "bindingScore", name: "Binding Score" },
          { id: "kind", name: "Type" }, // width: "10%" },
          { id: "id", name: "ID", attributes: { style: `text-gray-400` } }, //, width: "5%" },
        ]}
        pagination={{
          limit: itemLimit,
          buttonsCount: 2,
        }}
        className={{
          container: "overflow-x-scroll md:overflow-hidden whitespace-normal",
          table: "text-left border-b-2",
          thead: "bg-gray-200 text-base text-center",
          tbody: "text-sm text-center h-100 md:text-base",
          th: "py-2 px-2  hover:bg-gray-300 transition duration-200", // table header cells
          td: "", // table body cells
          pagination:
            "text-sm flex justify-between ml-3 mr-2 mt-2 md:ml-5 md:mr-0",
          paginationSummary: "mt-0.5",
          paginationButton: "border-2 px-1 md:px-2 mx-0.5 text-gray-500",
          paginationButtonPrev: "rounded-l-md",
          paginationButtonNext: "rounded-r-md md:mr-2",
          paginationButtonCurrent: "font-semibold bg-gray-200",
        }}
        language={{
          pagination: {
            previous: "Prev",
            showing: " ",
            navigate: (pages) => `Page ${itemLimit} of ${pages}`,
          },
        }}
      />
    </div>
  );
}

export default ResultsGrid;
