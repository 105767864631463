import heroAsset from "../assets/hero.png";
import { Title } from "./text";
import InformationButtonContainer from "./InformationButtonContainer";

/**
 * Banner with large image at top of the page
 * @constructor
 */
/* This example requires Tailwind CSS v2.0+ */
export default function Hero() {
  return (
    <div
      className="flex flex-col object-cover w-full align-bottom h-96"
      style={{ backgroundImage: `url(${heroAsset})` }}
    >
      <Title>Design your own superselector</Title>
      <InformationButtonContainer />
    </div>
  );
}
