import { ButtonDark, ButtonLight, ButtonMuted, ButtonMaroon } from "./buttons";
import { Subtitle } from "./text";
import GenomesList from "./GenomesList";
import SearchBar from "./SearchBar";
import { ContainerCard } from "./core";
import { useContext } from "react";
import { MainContext } from "./MainContent";
import { useState } from "react";

export default function SearchBox(props) {
  const {
    updateData,
    setGenome,
    targets,
    targetsHandler,
    nonTargets,
    nonTargetsHandler,
    clearSelection,
    setTargets,
    setNames,
    setNonTargets,
    names,
  } = useContext(MainContext);

  // useEffect for updating store with targets and non-targets
  const [searchActive, setSearchActive] = useState(1);

  const exampleGenomes = [
    { name: "COVID-alpha", _id: "61ead2433ffec02b6aff364e" },
    { name: "COVID-beta", _id: "61eade2f3ffec02b6aff3658" },
    { name: "COVID-delta", _id: "61eade363ffec02b6aff3659" },
    { name: "COVID-omicron", _id: "61eade3c3ffec02b6aff365a" },
    { name: "COVID-original", _id: "61fd01cebea42e45eecdb18a" },
  ];

  function setExample(targetIndex, nonTargetIndices) {
    // Add Target
    const tGenome = exampleGenomes[targetIndex];
    setTargets([tGenome]);
    // Filter Non-targets and add
    const ntGenomes = exampleGenomes.filter((genome, i) =>
      nonTargetIndices.includes(i)
    );
    setNonTargets([...ntGenomes]);

    // Set names for when
    const ntGenomesNames = ntGenomes.map((g) => g["name"]);
    setNames([...names, ...ntGenomesNames, tGenome["name"]]);
  }

  return (
    <div>
      <ContainerCard className="bg-white -mt-28 md:-mt-36 drop-shadow-xl">
        {/* Search and Add */}
        <Subtitle className="mx-4 my-2">Add Genomes</Subtitle>
        <div className="mx-auto"></div>

        {/* Toggle Bar */}
        <div class="flex flex-row">
          {/* Search section */}
          <div
            class={`self-center mr-2 flex flex-row cursor-pointer px-1 py-2 border-b-2 ${
              searchActive ? `border-black` : `border-gray-200`
            }`}
            onClick={() => setSearchActive(1)}
          >
            <svg
              class={searchActive ? `fill-black` : `fill-gray-400`}
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="search">
                  <rect width="24" height="24" opacity="0" />
                  <path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" />
                </g>
              </g>
            </svg>
            <span
              class={`mx-2 font-semibold ${
                searchActive ? `text-black` : `text-gray-400`
              }`}
            >
              Search
            </span>
          </div>
          {/* Example section */}
          <div
            class={`self-center mr-2 flex flex-row cursor-pointer px-1 py-2 border-b-2 ${
              searchActive ? `border-gray-200` : `border-black`
            }`}
            onClick={() => setSearchActive(0)}
          >
            <svg
              class={searchActive ? `fill-gray-400` : `fill-black`}
              width="24px"
              height="24px"
              // viewBox="0 0 24 24"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
            >
              <g>
                <g>
                  <path d="M0,0v237.268h237.268V0H0z M199.805,199.805H37.463V37.463h162.341V199.805z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M274.732,0v237.268H512V0H274.732z M474.537,199.805H312.195V37.463h162.341V199.805z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M0,274.732V512h237.268V274.732H0z M199.805,474.537H37.463V312.195h162.341V474.537z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M274.732,274.732V512H512V274.732H274.732z M474.537,474.537H312.195V312.195h162.341V474.537z" />
                </g>
              </g>
            </svg>
            <span
              class={`mx-2 font-semibold ${
                searchActive ? `text-gray-400` : `text-black`
              }`}
            >
              Examples
            </span>
          </div>
          <div class="mx-auto"></div>
          {/* Clear Button */}
          <ButtonMaroon
            onClick={clearSelection}
            className="px-2 py-0.5 mb-1 mr-1.5"
          >
            Clear
          </ButtonMaroon>
        </div>
        <hr class="stroke-2 bg-gray-200 h-0.5 mb-2 -mt-0.5" />

        {/* Search Bar */}
        {searchActive ? (
          <div>
            <span class="block m-4">
              <span class="font-semibold">
                Start typing to search for a genome.
              </span>{" "}
              Click the button "how I design a superselector" above to get
              started.
            </span>
            <div className="flex flex-col items-center justify-center md:flex-row">
              <SearchBar
                selectionHandler={setGenome}
                className="w-full mb-2 md:w-1/2 md:mb-0"
              />
              <div className="grid grid-cols-2 my-3 space-x-1 text-sm md:ml-4 md:text-base md:my-0">
                <ButtonLight
                  onClick={targetsHandler}
                  className="h-8 px-3 md:w-36"
                >
                  + Target
                </ButtonLight>
                <ButtonLight
                  onClick={nonTargetsHandler}
                  className="h-8 px-3 md:w-36"
                >
                  + Non-Target
                </ButtonLight>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <span class="block m-4">
              Choose a{" "}
              <span class="font-semibold">specific genome to target</span>, and
              see how the lists of targets and non-targets change.
            </span>
            <div class="grid grid-cols-2 md:grid-cols-5 gap-4 mx-6 md:mx-12 mb-2">
              <ButtonLight
                onClick={() => setExample(4, [0, 1, 2, 3])}
                className="px-2 text-sm md:px-6"
              >
                SARS-COV-2 Original Strain
              </ButtonLight>
              <ButtonLight
                onClick={() => setExample(0, [1, 2, 3, 4])}
                className="px-2 text-sm md:px-6"
              >
                SARS-COV-2 Alpha Strain
              </ButtonLight>
              <ButtonLight
                onClick={() => setExample(1, [0, 2, 3, 4])}
                className="px-2 text-sm md:px-6"
              >
                SARS-COV-2 Beta Strain
              </ButtonLight>
              <ButtonLight
                onClick={() => setExample(2, [0, 1, 3, 4])}
                className="px-2 text-sm md:px-6"
              >
                SARS-COV-2 Delta Strain
              </ButtonLight>
              <ButtonLight
                onClick={() => setExample(3, [0, 1, 2, 4])}
                className="px-2 text-sm md:px-6"
              >
                SARS-COV-2 Omicron Strain
              </ButtonLight>
            </div>
            {/* Buttons */}
          </div>
        )}

        {/* <div className="grid grid-cols-1 md:grid-cols-2">
          <GenomesGrid
            data={targets}
            name="Targets"
            className="mx-auto mt-3 align-center"
          ></GenomesGrid>
          <GenomesGrid
            data={nonTargets}
            name="Non-Targets"
            className="mx-auto md:mt-3 align-center"
          ></GenomesGrid>
        </div> */}
        <div className="grid grid-cols-1 mt-3 md:mt-5">
          <GenomesList targets={targets} nonTargets={nonTargets} />
        </div>
        <div className="flex flex-col m-4 gap-y-2 gap-x-4 md:flex-row">
          <ButtonDark onClick={updateData} className="w-full">
            Find Superselectors
          </ButtonDark>
          <ButtonMuted className="w-full">Compute Superselectors</ButtonMuted>
        </div>
        {/* <hr class="stroke-2 bg-gray-200 hidden h-0.5 md:mt-4 mt-2" /> */}
      </ContainerCard>
    </div>
  );
}
