// Apologies for the messiness - have tried to comment to keep it readable

import { Hero, Header, MainContent } from "./components";
import { useEffect } from "react";

// firebase settings
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {logEvent(analytics, 'page_view')})
  return (
    <div className="justify-center min-h-screen mx-auto bg-gradient-to-t from-gray-50 to-gray-300">
      <Header />
      <Hero />
      <MainContent />
    </div>
  );
}

export default App;
