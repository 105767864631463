import { SearchBox, Filter, ResultsTable } from "./index";
import { createContext, useState } from "react";
import { getData } from "../tools/data";

const MainContext = createContext({});

const url = "https://api.nanograb.com/superselectors";

export default function MainContent() {
  // Genomes Search Box
  const [targets, setTargets] = useState([]);
  const [nonTargets, setNonTargets] = useState([]);
  const [genome, setGenome] = useState({});
  const [names, setNames] = useState([]);

  function clearSelection() {
    setNonTargets([]);
    setNames([]);
    setTargets([]);
  }

  function targetsHandler() {
    console.log(genome.name);
    if (!names.includes(genome.name)) {
      setTargets([...targets, genome]);
      setNames([genome.name, ...names]);
    }
  }

  function nonTargetsHandler() {
    console.log(genome.name);
    if (!names.includes(genome.name)) {
      setNonTargets([...nonTargets, genome]);
      setNames([genome.name, ...names]);
    }
  }

  // Filters
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(20);
  const [minSelectivity, setMinSelectivity] = useState(0);
  const [maxSelectivity, setMaxSelectivity] = useState(100);

  // Results
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);

  const formatData = (data) => {
    console.log(data);
    const formatted = data
      .map((doc) => {
        const ID = doc["_id"];
        const sequence = doc["properties"]["sequence"];
        const firstThree = sequence.substring(0, 3);
        const lastThree = sequence.slice(-3);
        const seqHidden = firstThree + " **** " + lastThree;
        const length = doc["properties"]["length"];
        const kind = doc["properties"]["kind"];
        const selectivity = parseFloat(
          (doc["properties"]["selectivity"] * 100).toFixed(3)
        );
        const bindingScore = doc["properties"]["score"];
        const nonTargets = doc["nonTargets"];
        const targets = doc["targets"];
        return {
          id: ID,
          sequence: seqHidden,
          kind: kind,
          selectivity: selectivity,
          length: length,
          bindingScore: bindingScore,
          nonTargets: nonTargets,
          targets: targets,
        };
      })
      .filter(checkGenomesMatch);
    setData(formatted);
    setRawData(formatted);
  };

  function checkGenomesMatch(doc) {
    // console.log(doc["targets"][0]);
    const targetIds = targets.map((target) => {
      return target["_id"];
    });
    const nonTargetIds = nonTargets.map((target) => {
      return target["_id"];
    });
    if (
      doc["targets"].sort().toString() === targetIds.sort().toString() &&
      doc["nonTargets"].sort().toString() === nonTargetIds.sort().toString()
    ) {
      return true;
    }
  }

  function filterByLength(doc) {
    if (doc["length"] >= minLength && doc["length"] <= maxLength) {
      return true;
    }
  }

  function filterBySelectivity(doc) {
    console.log(doc["selectivity"] >= minSelectivity);
    if (
      doc["selectivity"] >= minSelectivity &&
      doc["selectivity"] <= maxSelectivity
    ) {
      return true;
    }
  }

  // Find superselectors
  const updateData = () => {
    getData(url, formatData);
  };

  const filterData = () => {
    console.log(minLength, maxLength, minSelectivity, maxSelectivity);
    const filteredData = rawData
      .filter(filterBySelectivity)
      .filter(filterByLength);
    setData(filteredData);
  };

  return (
    <MainContext.Provider
      value={{
        data,
        updateData,
        filterData,
        genome,
        setGenome,
        targets,
        targetsHandler,
        nonTargets,
        nonTargetsHandler,
        setMinLength,
        setMaxLength,
        setMinSelectivity,
        setMaxSelectivity,
        clearSelection,
        setTargets,
        setNonTargets,
        setNames,
        names,
      }}
    >
      <div className="max-w-screen-lg pb-20 mx-auto">
        <SearchBox />
        <Filter />
        <ResultsTable />
      </div>
    </MainContext.Provider>
  );
}

export { MainContext };
