import axios from "axios";

const CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Add Your Key Here!!!
axios.defaults.headers.common = {
  "X-API-Key": process.env.XCONNECT,
};

/**
 * Gets data from a common URL and uses the setter function
 * from React's "[x, setX] = useState();" hook
 *
 * @props {string} `url`
 * @props {function} `setter`
 * @returns
 */
function getData(url, handler) {
  const _fetch = async () => {
    const response = await axios({
      method: "get",
      url: url,
      CONFIG,
    });
    return response;
  };
  // Queued on the javascript task queue (completed after React Task Queue)
  // Fetch returns a promise, then & catch added to microtask queue
  _fetch()
    .then((response) => handler(response.data), console.log("Success!"))
    .catch((error) =>
      console.error(`Could not get data from ${url}: ${error}`)
    );
  return;
}

export { getData };
