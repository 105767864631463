import { WhiteInformation } from "./buttons";
import superselectorAsset from "../assets/superselector_description.png";

const options = [
  {
    id: 1,
    name: "What is a Superselector?",
    body: (
      <div class="">
        <p class="md:ml-2">
          A <strong>superselector</strong> is a device which is used to identify
          particular objects in a biological environment. It is a molecule made
          from DNA which recognises a unique molecule (or "biomarker") of a
          specified target. It is able to differentiate between very similar
          biomarkers, for example a COVID-19 delta strain, and a COVID-19
          omicron strain.
        </p>
        <img
          class="py-3 md:px-3 min-h-72"
          src={superselectorAsset}
          alt="Superselectors shown on a surface, binding specifically to their target."
        />
        <p>
          You can found out more on our website at{" "}
          <a class="underline text-bold" href="https://www.nanograb.com">
            https://www.nanograb.com
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 2,
    name: "How do I design a Superselector?",
    body: (
      <div class="mx-auto">
        <ol class="ml-4 md:ml-8 list-outside list-decimal md:space-y-1">
          <li>Search and choose a genome from the Dropdown menu</li>
          <li>
            To design superselectors which detect the genome, add it to the{" "}
            <span class="italic">Targets</span> list.
          </li>
          <li>
            Conversely, to maximise selectivity against it, add it to the{" "}
            <span class="italic">Non-Targets</span> list.
          </li>
          <li>
            When you've added all the genomes of interest, click{" "}
            <strong>Find Superselectors</strong>
          </li>
        </ol>
      </div>
    ),
  },
  {
    id: 3,
    name: "How do I buy a superselector?",
    body: (
      <div>
        You can order superselectors from us by:{" "}
        <ol class="ml-6 md:ml-8 md:mt-2 mt-1 list-outside list-decimal">
          <li>
            Finding the <strong>IDs</strong> of your preferred superselectors
          </li>
          <li>
            Dropping us an email at:{" "}
            <p class="select-all font-semibold">hello@nanograb.com</p>
          </li>
        </ol>
      </div>
    ),
  },
];

export default function InformationButtonContainer() {
  return (
    <div className="relative flex flex-col flex-wrap justify-center space-y-2 md:text-lg md:space-y-0 md:space-x-2 md:flex-row">
      {options.map((option) => (
        <div>
          <WhiteInformation className="" body={option.body} name={option.name}>
            {option.name}
          </WhiteInformation>
        </div>
      ))}
    </div>
  );
}
