import { useState } from "react";
import { Dialog } from "@headlessui/react";
import ButtonDark from "./ButtonDark";

function PopUp(props) {
  return (
    <Dialog
      open={props.modal}
      onClose={() => props.setModal(false)}
      // className="fixed items-center justify-center w-full h-full bg-gray-700 bg-opacity-50"
      className="absolute items-center justify-center w-full shadow-2xl top-24"
    >
      <div className="flex flex-col w-11/12 p-2 mx-auto text-left text-white bg-pink-800 border-2 border-black border-solid shadow-inner rounded-2xl">
        <p className="my-1 font-semibold text-center underline md:text-2xl md:my-2">
          {props.name}
        </p>
        <p className="px-2 py-1 leading-relaxed md:text-xl">{props.body}</p>
        <ButtonDark
          onClick={() => props.setModal(false)}
          className="self-end -mt-2 text-sm md:-mt-8 md:text-lg md:mb-1"
          children="Close"
          height="h-8 md:h-12"
        />
      </div>
    </Dialog>
  );
}

/**
 * A button with white borders and white text that opens a dialog in the centre
 * of the page
 * @param {*} props
 * @returns
 */
export default function WhiteInformation(props) {
  const [modal, setModal] = useState(false);

  return (
    <div className="flex flex-row items-center justify-center md:justify-between">
      <button
        className={`h-10 md:h-14 px-4 w-72 md:w-auto text-center text-white border-white border-2
        transition duration-200 ease-out rounded-full bg-white bg-opacity-20  hover:bg-opacity-0

          ${props.className}`}
        onClick={() => setModal(true)}
      >
        {props.children}
      </button>
      <PopUp
        modal={modal}
        setModal={setModal}
        name={props.name}
        body={props.body}
      />
    </div>
  );
}
