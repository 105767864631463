import { Grid } from "gridjs-react";
import { useEffect, useState } from "react";

function GenomesList(props) {
  // set up internal data structures
  const [genomes, setGenomes] = useState([]);
  const columns = [
    {
      id: "name",
      name: "Genome Name",
      // width: `50%`,
    },
    {
      id: "type",
      name: "Type",
      // width: `50%`,
    },
  ];

  function formatData(targets, nonTargets) {
    var t = [];
    var nt = [];
    if (targets !== null) {
      t = targets.map((item) => {
        return {
          type: "Target",
          name: item["name"],
        };
      });
    }
    if (nonTargets !== null) {
      nt = nonTargets.map((item) => {
        return {
          type: "Non-Target",
          name: item["name"],
        };
      });
    }
    return [...t, ...nt];
  }
  // // load data continuously
  useEffect(() => {
    const formatted = formatData(props.targets, props.nonTargets);
    console.log("Genome table data:", formatted);
    setGenomes(formatted);
  }, [props]);

  // render component
  return (
    <div className={`text-gray-800 align-center mx-auto ${props.className}`}>
      <Grid
        data={genomes}
        columns={columns}
        pagination={false}
        fixedHeader={true}
        className={{
          table: "border-2 border-black",
          thead: "bg-pink-800 text-white text-base text-center",
          tbody: "text-sm text-center md:text-base border-t-2 border-black",
          th: "h-9 font-semibold", // table header cells
          td: "h-8 px-4 border-2  ", // table body cells
        }}
        language={{
          noRecordsFound: "No genomes added yet to this list",
        }}
      />
    </div>
  );
}

export default GenomesList;
