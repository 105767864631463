export default function ButtonLight(props) {
  return (
    <button
      className={`text-center self-center ease-out rounded-full border-2 bg-gray-200 transition duration-300 hover:bg-opacity-75 hover:border-gray-500 shadow-md
        ${props.className}`}
      onClick={
        props.onClick
          ? props.onClick
          : () => alert("ButtonLight clicked! (no onClick set)")
      }
    >
      {props.children}
    </button>
  );
}
